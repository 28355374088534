var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validate)}}},[_c('div',{staticClass:"w-full"},[_c('ValidationProvider',{staticClass:"mb-2",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"type":"text","label":"Name","name":"name"},model:{value:(_vm.payload.name),callback:function ($$v) {_vm.$set(_vm.payload, "name", $$v)},expression:"payload.name"}}),(errors[0])?_c('p',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"flex lg:space-x-4"},[_c('div',{staticClass:"w-full lg:w-1/2"},[_c('ValidationProvider',{staticClass:"mb-2",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"type":"text","label":"Address 1","name":"address_1"},model:{value:(_vm.payload.address_1),callback:function ($$v) {_vm.$set(_vm.payload, "address_1", $$v)},expression:"payload.address_1"}}),(errors[0])?_c('p',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{staticClass:"mb-2",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"type":"text","label":"Town","name":"town"},model:{value:(_vm.payload.town),callback:function ($$v) {_vm.$set(_vm.payload, "town", $$v)},expression:"payload.town"}}),(errors[0])?_c('p',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"w-full lg:w-1/2"},[_c('BaseInput',{staticClass:"mb-2",attrs:{"type":"text","label":"Address 2","name":"address_2"},model:{value:(_vm.payload.address_2),callback:function ($$v) {_vm.$set(_vm.payload, "address_2", $$v)},expression:"payload.address_2"}}),_c('ValidationProvider',{staticClass:"mb-4",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"type":"text","label":"Postcode","name":"postcode"},model:{value:(_vm.payload.postcode),callback:function ($$v) {_vm.$set(_vm.payload, "postcode", $$v)},expression:"payload.postcode"}}),(errors[0])?_c('p',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)]),_c('BaseBtn',{attrs:{"type":"submit","text":_vm.payload.id ? 'Update' : 'Add'}}),_c('FlashMessage',{attrs:{"message":_vm.message,"error":_vm.error}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }