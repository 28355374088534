<template>
  <ValidationObserver ref="observer" tag="div" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(validate)">
      <div class="w-full">
        <ValidationProvider v-slot="{ errors }" tag="div" rules="required" class="mb-2">
          <BaseInput type="text" label="Name" name="name" v-model="payload.name" />
          <p class="text-sm text-red-600" v-if="errors[0]">{{ errors[0] }}</p>
        </ValidationProvider>
      </div>

      <div class="flex lg:space-x-4">
        <div class="w-full lg:w-1/2">
          <ValidationProvider v-slot="{ errors }" tag="div" rules="required" class="mb-2">
            <BaseInput type="text" label="Address 1" name="address_1" v-model="payload.address_1" />
            <p class="text-sm text-red-600" v-if="errors[0]">{{ errors[0] }}</p>
          </ValidationProvider>

          <ValidationProvider v-slot="{ errors }" tag="div" rules="required" class="mb-2">
            <BaseInput type="text" label="Town" name="town" v-model="payload.town" />
            <p class="text-sm text-red-600" v-if="errors[0]">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>

        <div class="w-full lg:w-1/2">
          <BaseInput type="text" label="Address 2" name="address_2" v-model="payload.address_2" class="mb-2" />

          <ValidationProvider v-slot="{ errors }" tag="div" rules="required" class="mb-4">
            <BaseInput type="text" label="Postcode" name="postcode" v-model="payload.postcode" />
            <p class="text-sm text-red-600" v-if="errors[0]">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>
      </div>

      <BaseBtn type="submit" :text="payload.id ? 'Update' : 'Add'" />
      <FlashMessage :message="message" :error="error" />
    </form>
  </ValidationObserver>
</template>

<script>
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  //setInteractionMode,
} from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required",
});

import { mapGetters } from "vuex";
import { getError } from "@/utils/helpers";
import BaseBtn from "@/components/BaseBtn";
import BaseInput from "@/components/BaseInput";
import SchoolService from "@/services/SchoolService";
import FlashMessage from "@/components/FlashMessage";

export default {
  name: "AuthUserForm",
  components: {
    BaseBtn,
    BaseInput,
    FlashMessage,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    attributes: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      payload: {
        name: "",
        address_1: "",
        address_2: "",
        town: "",
        postcode: "",
      },
      error: null,
      message: null,
    };
  },
  computed: {
    ...mapGetters("school", ["add", "update"]),
  },
  methods: {
    validate() {
      this.error = null;
      this.message = null;
      console.log("this.payload", this.payload);

      if (this.payload.id) {
        SchoolService.update(this.payload)
          .then(() => {
            this.message = "School updated.";
            this.$parent.close();
            this.$eventBus.$emit("schools:updated", true);
          })
          .catch((error) => (this.error = getError(error)));
      } else {
        SchoolService.add(this.payload)
          .then(() => {
            this.message = "School added.";
            this.$parent.close();
            this.$eventBus.$emit("schools:added", true);
          })
          .catch((error) => (this.error = getError(error)));
      }
    },
  },
  mounted() {
    if (this.attributes.payload) {
      this.payload = this.attributes.payload;
    }
  },
};
</script>
